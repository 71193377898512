import { createSlice, PayloadAction, createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { TaxResults, CalcTaxes } from "./calcUtils";
import Big from "big.js";

interface InputState {
  items: Item[];
  selected: number;
  prov: string;
  year: number;
}

export interface Item {
  type: string;
  amount: string;
}

const initialState: InputState = {
  items: [],
  selected: -1,
  prov: "ON",
  year: 2020,
};

export const calcSlice = createSlice({
  name: "income",
  initialState,
  reducers: {
    addItem: (state, action: PayloadAction<Item>) => {
      const { type, amount } = action.payload;
      state.items.push({
        type: type,
        amount: amount,
      });
    },
    removeItem: (state, action: PayloadAction<number>) => {
      //remove by index
      const index = action.payload;
      if (index >= 0 && index < state.items.length) {
        state.items.splice(index, 1);
      }
      if (state.selected === index) {
        state.selected = -1;
      } else if (state.selected > index) {
        state.selected--;
      }
    },
    setSelected: (state, action: PayloadAction<number>) => {
      const index = action.payload;
      if (index >= 0 && index < state.items.length) {
        state.selected = index;
      }
    },
    setProvince: (state, action: PayloadAction<string>) => {
      state.prov = action.payload;
    },
    setYear: (state, action: PayloadAction<number>) => {
      state.year = action.payload;
    },
  },
});

export const {
  addItem,
  removeItem,
  setSelected,
  setProvince,
  setYear,
} = calcSlice.actions;
export default calcSlice.reducer;

//selectors
export const selectItems = (state: RootState) => state.calc.items;
export const selectSelectedIndex = (state: RootState) => state.calc.selected;
export const selectProv = (state: RootState) => state.calc.prov;

export const selectTaxesResult = createSelector(
  [selectItems, selectProv],
  function (items: readonly Item[], prov: string): TaxResults {
    var r = CalcTaxes(items, prov);
    return r;
  }
);

export interface ItemTaxDiff {
  diff: Big;
}

export const selectItemResult = createSelector(
  [selectItems, selectProv, selectSelectedIndex],
  function (items: readonly Item[], prov: string, index: number): ItemTaxDiff {
    if (index < 0 || index >= items.length) {
      return {
        diff: new Big(0),
      };
    }

    var orig = CalcTaxes(items, prov);
    const clonedItems: Item[] = [];
    items.forEach((v, i) => {
      if (i !== index) {
        clonedItems.push(Object.assign({}, v));
      }
    });
    var diff = CalcTaxes(clonedItems, prov);

    var r = orig.totalTaxes.minus(diff.totalTaxes);

    return {
      diff: r,
    };
  }
);
