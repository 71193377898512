export function formatAmount(a: string, pad: number): string {
  if (a === "n/a") {
    return "-- ".padStart(pad, " ");
  }
  if (a.match(/^(0*)\.?(0*)$/g)) {
    return "0.00 ".padStart(pad, " ");
  }
  //if no decimal place, pad it with decimals first
  if (a.match(/^-?\d*$/g)) {
    a = a + ".00";
  }
  //if single decimal place, add a 0 after it
  if (a.match(/^-?\d*\.\d$/g)) {
    a = a + "0";
  }
  //if not negative pad extra space behind so () lines up
  var s = a.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  //check for negative sign
  if (s.match(/^-.*$/g)) {
    s = s.replace(/^-(.*)/g, "($1)");
  } else {
    s = s.replace(/^(.*)$/g, "$1 ");
  }
  //pad to 20 spaces
  s = s.padStart(18, " ");

  return s;
}
