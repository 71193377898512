import React from "react";

import { H4, Card, Elevation, Icon, Tooltip, Button } from "@blueprintjs/core";
import { selectTaxesResult, selectItemResult } from "./calcSlice";
import { formatAmount } from "utils";
import { useSelector } from "react-redux";
import { RootState } from "app/store";

import Big from "big.js";

export function ResultBox() {
  const { results, itemResults, hasSelection } = useSelector(
    (state: RootState) => {
      return {
        results: selectTaxesResult(state),
        itemResults: selectItemResult(state),
        hasSelection: state.calc.selected !== -1,
      };
    }
  );
  const [showDetails, setShowDetails] = React.useState(false);

  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };
  return (
    <div style={{ height: "100%" }}>
      <Card
        style={{
          textAlign: "left",
          // height: "100%",
          marginTop: "10px",
          // marginBottom: "10px",
        }}
        elevation={Elevation.TWO}
      >
        <H4 style={{ textAlign: "center" }}>Taxes Owing</H4>
        <p style={{ marginBottom: "5px" }}>
          Total taxable income{" "}
          <Tooltip
            content={
              <div>
                Taxable income refers to the adjusted income used for the
                purpose of calculating taxes.
                <br /> This includes adjustments such as gross up of dividend
                income and adjustment due to capital gain inclusion rate.
              </div>
            }
          >
            <Icon icon="help" />
          </Tooltip>{" "}
          :
          <span
            className="bp3-monospace-text"
            style={{ float: "right", whiteSpace: "pre" }}
          >
            {formatAmount(results.taxableIncome.toFixed(2), 10)}
          </span>
        </p>
        <p style={{ marginBottom: "5px", color: "#5C7080" }}>
          <span style={{ marginLeft: "10px" }}>Federal tax:</span>
          <span
            className="bp3-monospace-text"
            style={{ float: "right", whiteSpace: "pre" }}
          >
            {formatAmount(results.fedTaxes.toFixed(2), 10) + "          "}
          </span>
        </p>
        <p style={{ marginBottom: "5px", color: "#5C7080" }}>
          <span style={{ marginLeft: "10px" }}>Provincial tax:</span>
          <span
            className="bp3-monospace-text"
            style={{ float: "right", whiteSpace: "pre" }}
          >
            {formatAmount(results.provTaxes.toFixed(2), 10) + "          "}
          </span>
        </p>
        <p style={{ marginBottom: "5px", color: "#5C7080" }}>
          <span style={{ marginLeft: "10px" }}>
            CPP (self-employed){" "}
            <Tooltip
              content={
                <div>
                  This is the additional CPP owing due to self-employment
                  income. <br /> Note that this is 2x the usual CPP amount as
                  self-employed individuals must pay both the individual portion
                  and the employer portion.
                </div>
              }
            >
              <Icon icon="help" />
            </Tooltip>{" "}
            :
          </span>
          <span
            className="bp3-monospace-text"
            style={{ float: "right", whiteSpace: "pre" }}
          >
            {formatAmount(results.selfEmpCPP.toFixed(2), 10) + "          "}
          </span>
        </p>
        <p style={{ marginBottom: "5px" }}>
          Total taxes{" "}
          <Tooltip
            content={
              <div>
                This is the calculated total amount of taxes that would be owed
                on the personal tax return. <br /> It does not include EI and
                CPP for employees which are held at source.
              </div>
            }
          >
            <Icon icon="help" />
          </Tooltip>{" "}
          :
          <span
            className="bp3-monospace-text"
            style={{ float: "right", whiteSpace: "pre" }}
          >
            {formatAmount(results.totalTaxes.toFixed(2), 10)}
          </span>
        </p>
        <Button fill onClick={toggleDetails} style={{ marginTop: "10px" }}>
          {showDetails ? "Hide Details" : "Show Details"}
        </Button>
        {showDetails ? (
          <Card style={{ marginTop: "10px" }}>
            <p style={{ marginBottom: "5px" }}>
              Total income (actual){" "}
              <Tooltip
                content={
                  <div>
                    Total income (actual) is the sum of actual amounts received,
                    net of deductions.
                    <br />
                    This differs from taxable income as this does not include
                    any adjustments as required by the Income Tax Act.
                  </div>
                }
              >
                <Icon icon="help" />
              </Tooltip>{" "}
              :
              <span
                className="bp3-monospace-text"
                style={{ float: "right", whiteSpace: "pre" }}
              >
                {formatAmount(results.totalIncome.toFixed(2), 10)}
              </span>
            </p>
            <p style={{ marginBottom: "5px", color: "#5C7080" }}>
              <span style={{ marginLeft: "10px" }}>Total taxes:</span>
              <span
                className="bp3-monospace-text"
                style={{ float: "right", whiteSpace: "pre" }}
              >
                {formatAmount(results.totalTaxes.toFixed(2), 10) + "          "}
              </span>
            </p>
            <p style={{ marginBottom: "5px", color: "#5C7080" }}>
              <span style={{ marginLeft: "10px" }}>CPP (at source):</span>
              <span
                className="bp3-monospace-text"
                style={{ float: "right", whiteSpace: "pre" }}
              >
                {formatAmount(results.cppAtSource.toFixed(2), 10) +
                  "          "}
              </span>
            </p>
            <p style={{ marginBottom: "5px", color: "#5C7080" }}>
              <span style={{ marginLeft: "10px" }}>EI (at source):</span>
              <span
                className="bp3-monospace-text"
                style={{ float: "right", whiteSpace: "pre" }}
              >
                {formatAmount(results.eiAtSource.toFixed(2), 10) + "          "}
              </span>
            </p>
            <p style={{ marginBottom: "5px" }}>
              After tax income{" "}
              <Tooltip
                content={
                  <div>
                    This represents cash received on hand. Calculated as total
                    income (actual) minus taxes paid on the personal tax return
                    and any additional CPP/EI paid at source.
                  </div>
                }
              >
                <Icon icon="help" />
              </Tooltip>{" "}
              :
              <span
                className="bp3-monospace-text"
                style={{ float: "right", whiteSpace: "pre" }}
              >
                {formatAmount(results.afterTaxIncome.toFixed(2), 10)}
              </span>
            </p>
            <br />
            <p style={{ marginBottom: "5px" }}>
              <span>
                Marginal rate (%){" "}
                <Tooltip
                  content={
                    <div>
                      This is the tax rate for every additional $1 of income
                      (employment or other income) on top of the current income.
                      <br />
                      Note that the marginal rate for dividends (eligible and
                      non-eligible) and capital gain will be different.
                    </div>
                  }
                >
                  <Icon icon="help" />
                </Tooltip>{" "}
                :
              </span>
              <span
                className="bp3-monospace-text"
                style={{ float: "right", whiteSpace: "pre" }}
              >
                {formatAmount(
                  results.marginalRate.mul(new Big(100)).toFixed(2),
                  10
                )}
              </span>
            </p>
            <p style={{ marginBottom: "5px" }}>
              <span>
                Average rate (%){" "}
                <Tooltip
                  content={
                    <div>
                      Average rate is calculated as the total taxes paid
                      (including CPP/EI) divided by actual income.
                    </div>
                  }
                >
                  <Icon icon="help" />
                </Tooltip>{" "}
                :
              </span>
              <span
                className="bp3-monospace-text"
                style={{ float: "right", whiteSpace: "pre" }}
              >
                {formatAmount(
                  results.averageRate.mul(new Big(100)).toFixed(2),
                  10
                )}
              </span>
            </p>
            {hasSelection ? (
              <div>
                <br />
                <p style={{ marginBottom: "5px" }}>
                  <span>
                    Incremental tax{" "}
                    <Tooltip
                      content={
                        <div>
                          Incremental tax represents the additional tax burden
                          of the selected item.
                          <br />
                          In other words, this is how much taxes would be
                          reduced (increased) by if the selected item is
                          removed.
                        </div>
                      }
                    >
                      <Icon icon="help" />
                    </Tooltip>{" "}
                    :
                  </span>
                  <span
                    className="bp3-monospace-text"
                    style={{ float: "right", whiteSpace: "pre" }}
                  >
                    {formatAmount(itemResults.diff.toFixed(2), 10)}
                  </span>
                </p>
              </div>
            ) : null}
          </Card>
        ) : null}
      </Card>
    </div>
  );
}
