import React from "react";

import {
  H4,
  Card,
  ITreeNode,
  Tree,
  Button,
  Intent,
  InputGroup,
  HTMLSelect,
  ControlGroup,
  Elevation,
  Label,
  FormGroup,
  H6,
  Tooltip,
  Icon,
  Callout,
} from "@blueprintjs/core";
import { createSelector } from "@reduxjs/toolkit";
import {
  selectItems,
  Item,
  selectSelectedIndex,
  setSelected,
  addItem,
  removeItem,
  setProvince,
  setYear,
} from "./calcSlice";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "app/store";
import { formatAmount } from "utils";

var regDec = new RegExp(/^(\d+(\.\d{1,2})?|\.\d{1,2})$/);
var regAllowable = new RegExp(/^(\d+)?(\.)?(\d{1,2})?$/);

export function ListBox() {
  const { nodes, selected, prov, year } = useSelector((state: RootState) => {
    return {
      nodes: selectTreeNodes(state),
      selected: state.calc.selected,
      prov: state.calc.prov,
      year: state.calc.year,
    };
  });
  const dispatch = useDispatch();
  const [showIns, setShowIns] = React.useState<boolean>(false);

  const toggleShowIns = () => {
    setShowIns(!showIns);
  };
  const [itemData, setItemData] = React.useState<Item>({
    amount: "",
    type: "emp",
  });
  const handleDelete = (event: React.MouseEvent<HTMLElement>) => {
    dispatch(removeItem(selected));
  };
  const handleAmountChange = (value: string) => {
    //check if it's valid
    if (regAllowable.test(value)) {
      setItemData({
        amount: value,
        type: itemData.type,
      });
      return;
    }
  };

  const hangleTypeChanges = (value: string) => {
    setItemData({
      amount: itemData.amount,
      type: value,
    });
  };

  const handleAddItem = () => {
    //only add if valid
    if (regDec.test(itemData.amount)) {
      dispatch(
        addItem({
          amount: itemData.amount,
          type: itemData.type,
        })
      );
      setItemData({
        amount: "",
        type: itemData.type,
      });
    }
  };

  const handleOnClick = (nodeData: ITreeNode) => {
    dispatch(setSelected(Number(nodeData.id)));
  };

  const handleChangeProv = (event: React.ChangeEvent<HTMLSelectElement>) => {
    dispatch(setProvince(event.target.value));
  };

  const handleChangeYear = (event: React.ChangeEvent<HTMLSelectElement>) => {
    dispatch(setYear(Number(event.target.value)));
  };

  return (
    <div>
      <Card
        style={{
          textAlign: "left",
          // height: "100%",
          marginTop: "10px",
          // marginBottom: "10px",
        }}
        elevation={Elevation.TWO}
      >
        <H4 style={{ textAlign: "center" }}>Input</H4>
        <div className="row">
          <div className="col-xs-12">
            <Callout intent={Intent.PRIMARY} style={{ marginBottom: "10px" }}>
              Currently this calculator only supports Ontario for tax year 2020.
              We are working on adding support on all other provinces. Stay
              tuned.
            </Callout>
          </div>
          <div className="col-xs-12 col-md-6">
            <FormGroup inline label="Province" labelInfo="(required)">
              <HTMLSelect
                value={prov}
                disabled={true}
                options={[
                  { label: "Alberta", value: "AB" },
                  { label: "British Columbia", value: "BC" },
                  { label: "Manitoba", value: "MB" },
                  { label: "New Brunswick", value: "NB" },
                  { label: "Newfoundland", value: "NL" },
                  { label: "Nova Scotia", value: "NS" },
                  { label: "Northwest Territories", value: "NT" },
                  { label: "Nunavut", value: "NU" },
                  { label: "Ontario", value: "ON" },
                  { label: "Prince Edward Island", value: "PE" },
                  { label: "Quebec", value: "QC" },
                  { label: "Saskatchewan", value: "Sk" },
                  { label: "Yukon", value: "YT" },
                ]}
                onChange={handleChangeProv}
              ></HTMLSelect>
            </FormGroup>
          </div>
          <div className="col-xs-12 col-md-6">
            <FormGroup inline label="Tax Year" labelInfo="(required)">
              <HTMLSelect
                value={year}
                disabled={true}
                options={[
                  { label: "2019", value: 2019 },
                  { label: "2020", value: 2020 },
                ]}
                onChange={handleChangeYear}
              ></HTMLSelect>
            </FormGroup>
          </div>
        </div>

        <H6>Income and Deduction Items</H6>
        <Tree contents={nodes} onNodeClick={handleOnClick}></Tree>
        {selected !== -1 ? (
          <Button
            fill
            icon="trash"
            intent={Intent.DANGER}
            onClick={handleDelete}
            disabled={selected === -1}
            style={{ marginTop: "1em" }}
          >
            Delete Selected Item
          </Button>
        ) : null}
        <br />
        <form
          onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            handleAddItem();
          }}
        >
          <ControlGroup vertical={false}>
            <HTMLSelect
              value={itemData.type}
              options={[
                { label: "Employment income", value: "emp" },
                { label: "Self-employment income", value: "self" },
                { label: "Capital gains", value: "cg" },
                { label: "Eligible dividends", value: "ediv" },
                { label: "Non-eligible dividends", value: "nediv" },
                { label: "Other income", value: "otherinc" },
                { label: "RRSP deduction", value: "rrsp" },
                { label: "Other deduction", value: "otherded" },
              ]}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                hangleTypeChanges(e.target.value);
              }}
            ></HTMLSelect>
            <InputGroup
              placeholder="Amount"
              value={itemData.amount}
              intent={regDec.test(itemData.amount) ? "success" : "danger"}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleAmountChange(e.target.value);
              }}
              fill
            />
            <Button
              icon="plus"
              intent={Intent.PRIMARY}
              onClick={handleAddItem}
            />
          </ControlGroup>
        </form>

        <Button
          fill
          icon="help"
          onClick={toggleShowIns}
          style={{ marginTop: "1em" }}
        >
          {showIns ? "Hide Instructions" : "Show Instructions"}
        </Button>

        {showIns ? (
          <div>
            <ul>
              <li>Start by adding the relevant items</li>
              <li>
                All amounts should be actual amount (i.e. how much
                received/receivable). The calculator will automatically convert
                to taxable amounts where applicable.
              </li>
              <li>
                All amounts should be a postive figure. Any deductions will
                automatically be converted to negatives by the calculator
              </li>
              <li>
                Mouse over (or tap on) the <Icon icon="help" /> icon where
                applicable for additional information
              </li>
            </ul>
          </div>
        ) : null}
      </Card>
    </div>
  );
}

export function switchLabel(value: string): string {
  switch (value) {
    case "emp":
      return "Employment income:";
    case "self":
      return "Self-employment income:";
    case "cg":
      return "Capital gain/loss:";
    case "ediv":
      return "Eligible dividends:";
    case "nediv":
      return "Non-eligible dividends:";
    case "otherinc":
      return "Other income:";
    case "rrsp":
      return "RRSP deduction:";
    case "otherded":
      return "Other deductions:";
    default:
      return "??";
  }
}

const selectTreeNodes = createSelector(
  [selectItems, selectSelectedIndex],
  function (items: Item[], index: number): ITreeNode[] {
    return items.map((e, i) => {
      //flip the amount if rrsp
      var amount = e.amount;
      if (e.type === "rrsp" || e.type === "otherded") {
        amount = "-" + amount;
      }
      return {
        id: i,
        hasCaret: false,
        label: switchLabel(e.type),
        isSelected: i === index,
        key: i,
        secondaryLabel: <pre>{formatAmount(amount, 18)}</pre>,
      };
    });
  }
);
