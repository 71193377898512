import React from "react";
import { ListBox } from "features/calc/ListBox";
import { ResultBox } from "features/calc/ResultBox";
import { ItemDetails } from "features/calc/ItemDetails";
import { useSelector } from "react-redux";
import { RootState } from "app/store";
import { GraphBox } from "features/calc/GraphBox";
import { DetailedBreakdown } from "features/calc/DetailedBreakdown";
import { Navbar, NavbarGroup } from "@blueprintjs/core";
import { InstructionBox } from "features/calc/InstructionBox";

function App() {
  const { hasSelection } = useSelector((state: RootState) => {
    return {
      hasSelection: state.calc.selected !== -1,
    };
  });

  return (
    <div>
      <Navbar className="no-print">
        <NavbarGroup style={{ paddingBottom: "5px" }}>
          <img
            src="logo.png"
            alt="logo"
            style={{ width: "auto", height: "80%" }}
          />
        </NavbarGroup>
      </Navbar>
      <div className="row" style={{ marginTop: "15px" }}>
        <div className="col-xs-12 col-md-offset-2 col-md-8">
          <div className="box">
            <div className="row center-xs">
              <div className="col-xs-12">
                <ListBox />
              </div>
            </div>
            <div className="row center-xs">
              <div className="col-xs-12">
                <ResultBox />
              </div>
            </div>

            <div className="row center-xs">
              <div className="col-xs-12">
                <div
                  style={{
                    textAlign: "left",
                    // height: "100%",
                    marginTop: "10px",
                    // marginBottom: "10px",
                  }}
                >
                  <p>
                    The calculator only includes the following non refundable
                    tax credits: basic personal amount, CPP/QPP premium, EI
                    premium, and Canada employment amount. It does not include
                    premium such as Ontario Health Tax. The calculated amounts
                    should only be used as an estimation. The calculator does
                    not validate the accuracy and reasonableness of your input.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
