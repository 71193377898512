import Big from "big.js";

interface Brackets {
  upper: Big;
  rate: Big;
}

export const CurrentYear = 2020;
//brackets must be in order
export const TaxBrackets = new Map<string, Map<number, Brackets[]>>([
  [
    "Fed",
    new Map<number, Brackets[]>([
      [
        2020,
        [
          {
            upper: new Big(48535),
            rate: new Big(0.15),
          },
          {
            upper: new Big(97069),
            rate: new Big(0.205),
          },
          {
            upper: new Big(150473),
            rate: new Big(0.26),
          },
          {
            upper: new Big(214368),
            rate: new Big(0.29),
          },
          {
            upper: new Big(-1),
            rate: new Big(0.33),
          },
        ],
      ],
    ]),
  ],
  [
    "AB",
    new Map<number, Brackets[]>([
      [
        2020,
        [
          {
            upper: new Big(44740),
            rate: new Big(0.0505),
          },
          {
            upper: new Big(89482),
            rate: new Big(0.0915),
          },
          {
            upper: new Big(150000),
            rate: new Big(0.1116),
          },
          {
            upper: new Big(220000),
            rate: new Big(0.1216),
          },
          {
            upper: new Big(-1),
            rate: new Big(0.1316),
          },
        ],
      ],
    ]),
  ],
  [
    "ON",
    new Map<number, Brackets[]>([
      [
        2020,
        [
          {
            upper: new Big(44740),
            rate: new Big(0.0505),
          },
          {
            upper: new Big(89482),
            rate: new Big(0.0915),
          },
          {
            upper: new Big(150000),
            rate: new Big(0.1116),
          },
          {
            upper: new Big(220000),
            rate: new Big(0.1216),
          },
          {
            upper: new Big(-1),
            rate: new Big(0.1316),
          },
        ],
      ],
    ]),
  ],
]);

export const ONSurtaxLowerThreshold = new Big(4830);
export const ONSurtaxLowerRate = new Big(0.2);
export const ONSurtaxUpperThreshold = new Big(6182);
export const ONSurtaxUpperRate = new Big(0.36);

export const EliDivGrossUpFromActual = new Map<string, Big>([
  ["Fed", new Big(0.38)],
  ["ON", new Big(0.38)],
]);

export const EliDivTaxCreditFromGrossUp = new Map<string, Big>([
  ["Fed", new Big(6).div(new Big(11))],
  [
    "ON",
    new Big(0.1)
      .mul(EliDivGrossUpFromActual.get("ON")!.plus(new Big(1)))
      .div(EliDivGrossUpFromActual.get("ON")!),
  ], //10% of grossed up dividend or 0.10 * (1 + grossup)
]);

export const NonEliDivGrossUpFromActual = new Map<string, Big>([
  ["Fed", new Big(0.15)],
  ["ON", new Big(0.15)],
]);

export const NonEliDivTaxCreditFromGrossUp = new Map<string, Big>([
  ["Fed", new Big(9).div(new Big(13))],
  /**
   * Ontario:
   * Currently,
 section 19.1 of the Taxation Act, 2007 provides the Ontario dividend tax
 credit for years 2016 and later.  The section is amended to provide that the
 tax credit includes 22.895 per cent of the amount required under subparagraph
 82 (1) (b) (i) of the Income Tax Act (Canada) to be included in income
 for taxation years ending after December 31, 2019.
   */
  ["ON", new Big(0.22895)],
]);

interface CPPRate {
  limit: Big;
  exemption: Big;
  rate: Big;
}

interface EIRate {
  limit: Big;
  rate: Big;
}
export const CPPRates = new Map<number, CPPRate>([
  [
    2020,
    {
      limit: new Big(58700),
      exemption: new Big(3500),
      rate: new Big(0.0525),
    },
  ],
]);

export const EIRates = new Map<number, EIRate>([
  [2020, { limit: new Big(54200), rate: new Big(0.0158) }],
]);

interface CreditItems {
  Personal: Big;
  EmploymentAmount: Big;
}

export const Credits = new Map<string, Map<number, CreditItems>>([
  [
    "Fed",
    new Map<number, CreditItems>([
      [
        2020,
        {
          Personal: new Big(12298),
          EmploymentAmount: new Big(1245),
        },
      ],
    ]),
  ],
  [
    "AB",
    new Map<number, CreditItems>([
      [
        2020,
        {
          Personal: new Big(19369),
          EmploymentAmount: new Big(0),
        },
      ],
    ]),
  ],
  [
    "BC",
    new Map<number, CreditItems>([
      [
        2020,
        {
          Personal: new Big(10949),
          EmploymentAmount: new Big(0),
        },
      ],
    ]),
  ],
  [
    "MB",
    new Map<number, CreditItems>([
      [
        2020,
        {
          Personal: new Big(9838),
          EmploymentAmount: new Big(0),
        },
      ],
    ]),
  ],
  [
    "NB",
    new Map<number, CreditItems>([
      [
        2020,
        {
          Personal: new Big(10459),
          EmploymentAmount: new Big(0),
        },
      ],
    ]),
  ],
  [
    "NL",
    new Map<number, CreditItems>([
      [
        2020,
        {
          Personal: new Big(9498),
          EmploymentAmount: new Big(0),
        },
      ],
    ]),
  ],
  [
    "ON",
    new Map<number, CreditItems>([
      [
        2020,
        {
          Personal: new Big(10783),
          EmploymentAmount: new Big(0),
        },
      ],
    ]),
  ],
]);

//0.174096
