import Big from "big.js";
import { Item } from "./calcSlice";
import {
  EliDivGrossUpFromActual,
  EliDivTaxCreditFromGrossUp,
  NonEliDivGrossUpFromActual,
  NonEliDivTaxCreditFromGrossUp,
  CPPRates,
  EIRates,
  TaxBrackets,
  CurrentYear,
  Credits,
  ONSurtaxLowerRate,
  ONSurtaxLowerThreshold,
  ONSurtaxUpperThreshold,
  ONSurtaxUpperRate,
} from "./constants";

export interface TaxResults {
  taxableIncome: Big;
  fedTaxes: Big;
  provTaxes: Big;
  selfEmpCPP: Big;
  totalTaxes: Big;
  totalIncome: Big;
  cppAtSource: Big;
  eiAtSource: Big;
  afterTaxIncome: Big;
  marginalRate: Big;
  averageRate: Big;
}

export function CalcTaxes(items: readonly Item[], prov: string): TaxResults {
  var r = {
    taxableIncome: new Big(0),
    fedTaxes: new Big(0),
    provTaxes: new Big(0),
    selfEmpCPP: new Big(0),
    totalTaxes: new Big(0),
    totalIncome: new Big(0),
    cppAtSource: new Big(0),
    eiAtSource: new Big(0),
    afterTaxIncome: new Big(0),
    marginalRate: new Big(0),
    averageRate: new Big(0),
  };

  //calculate taxable income
  var empIncome = new Big(0);
  var selfEmpIncome = new Big(0);
  var totalDivCreditFed = new Big(0);
  var totalDivCreditProv = new Big(0);

  items.forEach((v) => {
    switch (v.type) {
      case "emp":
        empIncome = empIncome.plus(new Big(v.amount));
        r.taxableIncome = r.taxableIncome.plus(new Big(v.amount));
        r.totalIncome = r.totalIncome.plus(new Big(v.amount));
        break;
      case "self":
        selfEmpIncome = selfEmpIncome.plus(new Big(v.amount));
        r.taxableIncome = r.taxableIncome.plus(new Big(v.amount));
        r.totalIncome = r.totalIncome.plus(new Big(v.amount));
        break;
      case "otherinc":
        r.taxableIncome = r.taxableIncome.plus(new Big(v.amount));
        r.totalIncome = r.totalIncome.plus(new Big(v.amount));
        break;
      case "cg":
        var cg = new Big(v.amount);
        var tcg = cg.div(new Big(2));
        r.taxableIncome = r.taxableIncome.plus(tcg);
        r.totalIncome = r.totalIncome.plus(new Big(v.amount));
        break;
      case "ediv":
        var t = CalcTaxableEliDiv(v);
        var taxable = t.actual.plus(t.grossup.get("Fed")!);
        r.taxableIncome = r.taxableIncome.plus(taxable);
        totalDivCreditFed = totalDivCreditFed.plus(t.credit.get("Fed")!);
        totalDivCreditProv = totalDivCreditProv.plus(t.credit.get(prov)!);
        r.totalIncome = r.totalIncome.plus(new Big(v.amount));
        break;
      case "nediv":
        var t = CalcTaxableNonEliDiv(v);
        var taxable = t.actual.plus(t.grossup.get("Fed")!);
        r.taxableIncome = r.taxableIncome.plus(taxable);
        totalDivCreditFed = totalDivCreditFed.plus(t.credit.get("Fed")!);
        totalDivCreditProv = totalDivCreditProv.plus(t.credit.get(prov)!);
        r.totalIncome = r.totalIncome.plus(new Big(v.amount));
        break;
      case "rrsp":
      /* falls through */
      case "otherded":
        r.taxableIncome = r.taxableIncome.minus(new Big(v.amount));
        r.totalIncome = r.totalIncome.minus(new Big(v.amount));
        break;
      default:
        return new Big(0);
    }
  });

  var cpp = CalcCPP(empIncome, selfEmpIncome);
  r.selfEmpCPP = cpp.SelfCPP;
  r.cppAtSource = cpp.EmpCPP;
  //subtract by half of self employed cpp
  r.taxableIncome = r.taxableIncome.minus(cpp.SelfCPP.div(new Big(2)));
  var ei = CalcEI(empIncome);
  r.eiAtSource = ei;
  console.log("total ei: " + ei);

  //calculate actual taxes
  r.fedTaxes = CalcFedTaxes(
    r.taxableIncome,
    empIncome.plus(selfEmpIncome),
    cpp.EmpCPP.plus(cpp.SelfCPP.div(new Big(2))),
    ei,
    totalDivCreditFed
  );

  //calculate prov taxes
  r.provTaxes = CalcProvTaxes(
    prov,
    r.taxableIncome,
    empIncome.plus(selfEmpIncome),
    cpp.EmpCPP.plus(cpp.SelfCPP.div(new Big(2))),
    ei,
    totalDivCreditProv
  );

  r.totalTaxes = r.fedTaxes.plus(r.provTaxes.plus(r.selfEmpCPP));

  r.afterTaxIncome = r.totalIncome.minus(
    r.totalTaxes.plus(r.cppAtSource.plus(r.eiAtSource))
  );

  //calculate average rate as fraction of total income (not taxable income)
  r.averageRate = r.totalTaxes.plus(r.cppAtSource.plus(r.eiAtSource));
  if (r.totalIncome.gt(new Big(0))) {
    r.averageRate = r.averageRate.div(r.totalIncome);
  } else {
    r.averageRate = new Big(0);
  }

  //calculate marginal rate by adding $1 to taxable income
  var mIncome = r.taxableIncome.plus(new Big(1));
  var mFed = CalcFedTaxes(
    mIncome,
    empIncome.plus(selfEmpIncome),
    cpp.EmpCPP.plus(cpp.SelfCPP.div(new Big(2))),
    ei,
    totalDivCreditFed
  );
  var mProv = CalcProvTaxes(
    prov,
    mIncome,
    empIncome.plus(selfEmpIncome),
    cpp.EmpCPP.plus(cpp.SelfCPP.div(new Big(2))),
    ei,
    totalDivCreditProv
  );
  var mTotal = mFed.plus(mProv.plus(r.selfEmpCPP));

  r.marginalRate = mTotal.minus(r.totalTaxes);

  return r;
}

export interface TaxableDiv {
  actual: Big;
  grossup: Map<string, Big>;
  credit: Map<string, Big>;
}

export function CalcTaxableEliDiv(item: Item): TaxableDiv {
  var r = {
    actual: new Big(0),
    grossup: new Map<string, Big>(),
    credit: new Map<string, Big>(),
  };

  if (item.type !== "ediv") {
    return r;
  }

  r.actual = new Big(item.amount);

  //fed
  r.grossup.set("Fed", r.actual.mul(EliDivGrossUpFromActual.get("Fed")!));
  r.credit.set(
    "Fed",
    r.actual
      .mul(EliDivGrossUpFromActual.get("Fed")!)
      .mul(EliDivTaxCreditFromGrossUp.get("Fed")!)
  );

  //ON
  r.grossup.set("ON", r.actual.mul(EliDivGrossUpFromActual.get("ON")!));
  //tax credit = 10% of grossed up dividend
  r.credit.set(
    "ON",
    r.actual
      .mul(EliDivGrossUpFromActual.get("ON")!)
      .mul(EliDivTaxCreditFromGrossUp.get("ON")!)
  );

  console.log("eli div details:");
  console.log("\tgrossed up:", r.grossup.get("Fed")!.toFixed(2));
  console.log("\tfed credit:", r.credit.get("Fed")!.toFixed(2));
  console.log("\ton credit:", r.credit.get("ON")!.toFixed(2));

  return r;
}

export function CalcTaxableNonEliDiv(item: Item): TaxableDiv {
  var r = {
    actual: new Big(0),
    grossup: new Map(),
    credit: new Map(),
  };

  if (item.type !== "nediv") {
    return r;
  }

  r.actual = new Big(item.amount);

  //fed
  r.grossup.set("Fed", r.actual.mul(NonEliDivGrossUpFromActual.get("Fed")!));
  r.credit.set(
    "Fed",
    r.actual
      .mul(NonEliDivGrossUpFromActual.get("Fed")!)
      .mul(NonEliDivTaxCreditFromGrossUp.get("Fed")!)
  );

  //ON
  r.grossup.set("ON", r.actual.mul(NonEliDivGrossUpFromActual.get("ON")!));
  //tax credit = 10% of grossed up dividend
  r.credit.set(
    "ON",
    r.actual
      .mul(NonEliDivGrossUpFromActual.get("ON")!)
      .mul(NonEliDivTaxCreditFromGrossUp.get("ON")!)
  );

  console.log("non eli div details:");
  console.log("\tgrossed up:", r.grossup.get("Fed")!.toFixed(2));
  console.log("\tfed credit:", r.credit.get("Fed")!.toFixed(2));
  console.log("\ton credit:", r.credit.get("ON")!.toFixed(2));

  return r;
}

interface CPPPayable {
  EmpCPP: Big;
  SelfCPP: Big;
}

export function CalcCPP(totalEmp: Big, totalSelfEmp: Big): CPPPayable {
  //cpp is calculated on totalEmp first; then self emp if excess

  var rates = CPPRates.get(CurrentYear)!;

  //adjust income for exemptoin
  var adjEmpInc = totalEmp.minus(rates.exemption);
  var adjSelfInc = totalSelfEmp;

  if (adjEmpInc.lt(new Big(0))) {
    adjSelfInc = adjEmpInc.plus(adjSelfInc);
    adjEmpInc = new Big(0);
  }

  if (adjSelfInc.lt(new Big(0))) {
    adjSelfInc = new Big(0);
  }

  if (rates.limit.lt(totalEmp)) {
    var cpp = rates.limit.minus(rates.exemption);
    cpp = cpp.mul(rates.rate);
    // we already hit the limit so self employed cpp doesn't matter
    return {
      EmpCPP: cpp,
      SelfCPP: new Big(0),
    };
  }

  //calculate employment portion
  var cpp = adjEmpInc.mul(rates.rate);

  //calculate total cpp with self employment
  var totalEmpInc = adjEmpInc.plus(adjSelfInc);
  var totalCPP = totalEmpInc.mul(rates.rate);

  if (totalEmpInc.gte(rates.limit)) {
    totalCPP = rates.limit.minus(rates.exemption);
    totalCPP = totalCPP.mul(rates.rate);
  }

  return {
    EmpCPP: cpp,
    SelfCPP: totalCPP.minus(cpp).mul(new Big(2)),
  };
}

export function CalcEI(totalEmp: Big): Big {
  var rates = EIRates.get(CurrentYear)!;
  if (rates.limit.gte(totalEmp)) {
    return rates.rate.mul(totalEmp);
  }

  return rates.limit.mul(rates.rate);
}

export function CalcFedTaxes(
  taxableIncome: Big,
  emp: Big,
  cpp: Big,
  ei: Big,
  divCredit: Big
): Big {
  //taxable income multiplied by brackets
  var taxes = new Big(0);

  var brackets = TaxBrackets.get("Fed")!.get(CurrentYear)!;

  var last = new Big(0);

  brackets.forEach((b, i) => {
    //lower threshold of each bracket is last
    //if > last, then calculate this bracket
    //else ignore
    if (taxableIncome.gt(last)) {
      //check if taxableIncome is at upper threshold unless upper is -1
      if (taxableIncome.gt(b.upper) && !b.upper.eq(new Big(-1))) {
        //if so, only cal up to upper threshold
        var t = b.rate.mul(b.upper.minus(last));
        taxes = taxes.plus(t);
      } else {
        var t = b.rate.mul(taxableIncome.minus(last));
        taxes = taxes.plus(t);
      }
    }

    last = b.upper;
  });

  var credits = new Big(0);
  var fedCredits = Credits.get("Fed")!.get(2020)!;

  //calculate credits

  //personal credit
  credits = credits.plus(fedCredits.Personal);

  //emp amount

  if (emp.gt(new Big(0))) {
    if (emp.lt(fedCredits.EmploymentAmount)) {
      credits = credits.add(emp);
    } else {
      credits = credits.add(fedCredits.EmploymentAmount);
    }
  }

  //cpp+ei
  credits = credits.plus(cpp);
  credits = credits.plus(ei);

  taxes = taxes.minus(credits.mul(new Big(brackets[0].rate)));

  if (taxes.lt(new Big(0))) {
    taxes = new Big(0);
  }

  //dividend tax credit
  taxes = taxes.minus(divCredit);

  if (taxes.lt(new Big(0))) {
    taxes = new Big(0);
  }

  //AMT???

  return taxes;
}

export function CalcProvTaxes(
  prov: string,
  taxableIncome: Big,
  emp: Big,
  cpp: Big,
  ei: Big,
  divCredit: Big
): Big {
  //taxable income multiplied by brackets
  var taxes = new Big(0);

  //check prov
  if (!TaxBrackets.has(prov)) {
    throw new Error(`nonexistant prov ${prov}`);
  }

  var brackets = TaxBrackets.get(prov)!.get(CurrentYear)!;

  console.log("prov taxable inc: " + taxableIncome.toString());

  var last = new Big(0);

  brackets.forEach((b, i) => {
    //lower threshold of each bracket is last
    //if > last, then calculate this bracket
    //else ignore
    if (taxableIncome.gt(last)) {
      //check if taxableIncome is at upper threshold unless upper is -1
      if (taxableIncome.gt(b.upper) && !b.upper.eq(new Big(-1))) {
        //if so, only cal up to upper threshold
        var t = b.rate.mul(b.upper.minus(last));
        taxes = taxes.plus(t);
      } else {
        var t = b.rate.mul(taxableIncome.minus(last));
        taxes = taxes.plus(t);
      }
    }

    last = b.upper;
  });

  console.log("prov taxes before credits: " + taxes.toString());

  var credits = new Big(0);
  var provCredits = Credits.get(prov)!.get(2020)!;

  //calculate credits

  //personal credit
  credits = credits.plus(provCredits.Personal);

  //emp amount (ONLY FOR YUKON BUT WE'LL LEAVE IT HERE)
  if (!provCredits.EmploymentAmount.eq(new Big(0))) {
    if (emp.gt(new Big(0))) {
      if (emp.lt(provCredits.EmploymentAmount)) {
        credits = credits.add(emp);
      } else {
        credits = credits.add(provCredits.EmploymentAmount);
      }
    }
  }

  //cpp+ei
  credits = credits.plus(cpp);
  console.log("prov cpp credit: " + cpp);
  credits = credits.plus(ei);
  console.log("prov ei credit: " + ei);
  console.log("prov credits: " + credits);

  taxes = taxes.minus(credits.mul(new Big(brackets[0].rate)));

  if (taxes.lt(new Big(0))) {
    taxes = new Big(0);
  }
  console.log("prov taxes before surtax: " + taxes);

  //calculate surtax if ON

  if (prov === "ON") {
    var surtax = new Big(0);

    if (taxes.gt(ONSurtaxLowerThreshold)) {
      surtax = surtax.plus(
        ONSurtaxLowerRate.mul(taxes.minus(ONSurtaxLowerThreshold))
      );

      console.log(
        "ontario - excess of lower surtax: " +
          taxes.minus(ONSurtaxLowerThreshold)
      );
    }
    if (taxes.gt(ONSurtaxUpperThreshold)) {
      surtax = surtax.plus(
        ONSurtaxUpperRate.mul(taxes.minus(ONSurtaxUpperThreshold))
      );

      console.log(
        "ontario - excess of upper surtax: " +
          taxes.minus(ONSurtaxUpperThreshold)
      );
    }
    taxes = taxes.plus(surtax);
  }
  console.log("prov taxes after surtax: " + taxes);

  console.log("prov div tax credit: " + divCredit);
  //dividend tax credit
  taxes = taxes.minus(divCredit);

  if (taxes.lt(new Big(0))) {
    taxes = new Big(0);
  }

  console.log("prov taxes after div tax credit: " + taxes);

  return taxes;
}

interface TaxBracketInfo {
  upper: Big;
  rate: Big;
  taxes: Big; //total taxes for this bracket
}

export function EffectiveTaxBracket(prov: string): TaxBracketInfo[] {
  //should just be a join of prov + federal

  //ontario have to add in additional cut off re surtax
  return [];
}

//on health premium calc
